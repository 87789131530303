@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.sortByContainer {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-self: flex-start;
  padding-left: 8px;

  @include media-breakpoint-down('md') {
    width: 50%;
  }
}

.sortBy {
  position: relative;
  background-color: $colour-white;
  flex-grow: 1;
  max-width: 100%;

  @include media-breakpoint-up('lg') {
    flex-grow: 0;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
